@import 'variables';
*,
*::after,
*::before {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.button-reset {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}
.secondary-link,
.primary-link {
  border-radius: $radius-small;
  padding: 2px 4px;
  text-decoration: none;
  font-weight: 600;
}
.primary-link {
  color: $grey-darker;
  background: $light-blue;
}
.secondary-link {
  color: $grey-darker;
  background: #cce3e4;
}
