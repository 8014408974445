//colors
$white: #fff;
$black: #000;
$orange: #ee6c4d;
$green: #6aebc0;
$light-blue: #e0fbfc;
$dark-blue: #98c1d9;
$darker-blue: #293241;

$primary: $orange;
$secondary: $darker-blue;

$white-primary: #eeeeee;
$white-darker: #c7c5c5c9;

$grey: rgb(215, 215, 215);
$grey-lighter: #393e46;
$grey-more-lighter: #505762;
$grey-darker: #232931;

$radius-small: 3px;

$padding-left-right: 6vw;
$padding-top-bottom: 30px;
