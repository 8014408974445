@import '~assets/scss/variables';

.accordion__button {
  cursor: pointer;
  width: 100%;
  border: none;
  position: relative;
  border-bottom: 1px solid $white-darker;
  padding: $padding-top-bottom / 3 0;
  &::after {
    content: url('../icons/arrow.svg');
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    transition: transform 0.2s;
  }
}
.accordion__button[aria-expanded='true']::after,
.accordion__button[aria-selected='true']::after {
  transform: rotate(90deg);
}
.accordion__panel {
  animation: show-panel 0.2s;
}
.twitter-picker div:nth-child(3) {
  display: flex;
  flex-wrap: wrap;
}
.twitter-picker div:first-child {
  border: none !important;
}
.twitter-picker div:nth-child(2) {
  display: none;
}
[hidden] {
  display: none;
}
@keyframes show-panel {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
